.bigbox {
	position: relative;
	height: calc(100% - 0.5rem);
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	z-index: 1;
}

.bigbox .section {
	display: none;
}
.nav {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.5rem;
	z-index: 100;
}
.nav ul {
	display: flex;
	justify-content: space-around;
	background: #fff;
	border-top:0.5px solid #ddd;
}
.nav li {
	text-decoration: none;
	height:0.5rem;
	padding-top:0.04rem;
	line-height: 0.18rem;
	font-size:0.12rem;
	color:#808080;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.nav li:nth-of-type(1){
	color:#BE9567;
}
