html {
	font-size: 100px;
}
body {
	font-size: 16px;
}
* {
	box-sizing: border-box;
}
html,
body {
	position: fixed;
	top: 0;
	left: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}
body,
p,
h1,
h2,
h3,
ul,
fieldset,
legend,
li {
	margin: 0;
	padding: 0;
}
img {
	display: block;
}
li {
	list-style: none;
}
