header {
	position: relative;
}

header img {
	width: 100%;
	height: 1.37rem;
}
section .si {
	display: flex;
	background: #fff;
	margin-bottom: 0.02rem;
	height: 0.47rem;
	line-height: 0.47rem;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.18rem;
	font-size: 0.18rem;
}
section .si h3 {
	font-size: 0.18rem;
}
section .si h3 span {
	font-size: 0.16rem;
}
section .si i {
	color: #be9567;
	font-size: 0.2rem;
}
section .shebei {
	display: flex;
	align-items: flex-end;
}
section .shebei li {
	width: 2px;
	height: 0.2rem;
	background: #ccc;
	margin-right: 1px;
}
section .shebei li:nth-of-type(5) {
	height: 0.14rem;
}
section .shebei li:nth-of-type(4) {
	height: 0.12rem;
}
section .shebei li:nth-of-type(3) {
	height: 0.09rem;
}
section .shebei li:nth-of-type(2) {
	height: 0.06rem;
}
section .shebei li:nth-of-type(1) {
	height: 0.04rem;
}
section .gongdeng {
	padding: 0.08rem 0.18rem 0.25rem;
	background: #fff;
	margin-top: 0.15rem;
}
section .gongdeng h3 {
	font-size: 0.18rem;
	line-height: 0.32rem;
}
section .gongdeng h3 i {
	color: #be9567;
	font-size: 0.2rem;
}
section .deng {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
section .deng p {
	width: 30%;
	display: flex;
	height: 0.81rem;
	width: 1rem;
	justify-content: center;
	align-items: center;
	font-size: 0.14rem;
	flex-direction: column;
	border-radius: 0.1rem;
	margin-top: 0.13rem;
	opacity: 0.4;
	box-shadow: 0rem 0rem 0.04rem 0.01rem rgb(219, 218, 218);
}
section .deng p img {
	width: 0.31rem;
	height: 0.43rem;
}
section .deng p span {
	line-height: 0.22rem;
	font-size: 0.12rem;
	color: #be9567;
}
section .fu {
	padding-bottom: 0.27rem;
	margin: 0.15rem 0;
	background: #fff;
}
section .fu h3 {
	line-height: 0.47rem;
	text-indent: 0.35rem;
	background: url(../../images/ping.png) no-repeat 0.18rem 0.14rem;
	font-size: 0.18rem;
}

section .fu .select {
	min-height: 0.92rem;
	margin: 0.04rem 0.15rem 0 0.15rem;
	padding: 0.1rem;
	border-radius: 20px;
	box-shadow: 0px 0px 0.1rem 0.01rem rgba(190, 149, 103, 0.4) inset;
}
section .fu .select .name {
	font-size: 0.13rem;
	line-height: 0.33rem;
	text-indent: 0.21rem;
	color: #be9567;
}
section .fu .select .name input {
	width: 2rem;
	line-height: 0.33rem;
	margin-left: 0.14rem;
	border: none;
	outline: none;
}
section .fu .select .xin {
	display: flex;
	border-top: 1px solid #fafafa;
}
section .fu .select .yuan {
	text-indent: 0.14rem;
	color: #333333;
	flex: 1;
	background: url(../../images/jiantou.png) no-repeat right center;
	margin-right:0.15rem;
}

section .button {
	padding: 0.3rem 0.18rem;
	background: #fff;
	font-size: 0.17rem;
}

