html,
body {
	height: 100%;
}
.meal {
	display: flex;
	height: 100%;
	flex-direction: column;
	background: #fff;
}
.header {
	height: 0.4rem;
}
.header .shebei {
	display: flex;
	align-items: flex-end;
	margin-right: 5px;
}
.header .shebei li {
	width: 2px;
	height: 0.2rem;
	background: #ccc;
	margin-right: 1px;
}
.header .shebei li:nth-of-type(5) {
	height: 0.14rem;
}
.header .shebei li:nth-of-type(4) {
	height: 0.12rem;
}
.header .shebei li:nth-of-type(3) {
	height: 0.09rem;
}
.header .shebei li:nth-of-type(2) {
	height: 0.06rem;
}
.header .shebei li:nth-of-type(1) {
	height: 0.04rem;
}
.content {
	flex: 1;
	overflow: auto;
	position: relative;
}
.section_style {
	padding: 0 0.2rem;
	padding-top: 0.15rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.section_style li {
	width: 1rem;
	border: 1px solid rgba(190, 149, 103, 0.15);
	border-radius: 0.07rem;
	margin-bottom: 0.22rem;
	padding: 0.07rem 0;
	position: relative;
	overflow: hidden;
}
.section_style li:nth-of-type(1) {
	border: 1px solid rgba(190, 149, 103, 1);
}

.section_style li p {
	text-align: center;
}
.section_style li .icons {
	display: none;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 0.14rem;
	height: 0.14rem;
	background: #be9567;
	color: #fff;
	border-radius: 0.05rem 0 0 0;
	text-align: center;
	line-height: 0.16rem;
	font-size: 0.12rem;
}
.section_style li:nth-of-type(1) .icons {
	display: block;
}
.section_style li .time {
	color: #be9567;
	font-size: 0.13rem;
	line-height: 0.27rem;
}
.section_style li .time img {
	width: 0.1rem;
	height: 0.13rem;
	display: inline-block;
	margin-right: 0.02rem;
}
.section_style li .money {
	color: #333;
	font-size: 0.18rem;
	line-height: 0.32rem;
}
// .explain {
// }
.explain {
	position: absolute;
	bottom: 0.1rem;
	padding: 0 0.2rem;
	font-size: 0.12rem;
	color: #6e6d6d;
	line-height: 0.15rem;
	.text {
		display: flex;
		p {
			span {
				color: #242424;
			}
		}
	}
}
// .explain .text p span{
//   color:#242424;
// }
.footer {
	height: 0.5rem;
	background: #439057;
	color: #fff;
	font-size: 0.22rem;
	line-height: 0.5rem;
	text-align: center;
}
.tcpage {
	width: 2.28rem !important;
	:global {
		.am-modal-content {
			padding-top: 0 !important;
			background: rgba(255, 255, 255, 0);
			.am-modal-close {
				right: 0.03rem !important;
				top: 0.03rem !important;
			}
			.am-modal-body {
				padding: 0 !important;
				border-radius: 0.06rem;
				line-height: 0.16rem !important;
			}
			.am-modal-footer {
				margin-top: 0.2rem;
				background: rgba(0, 0, 0, 0);
				width: 0.16rem !important;
				height: 0.16rem !important;
				margin-left: 1.06rem;
				background-image: url(../../images/chahao.png);
				background-size: 100% 100%;
				.am-modal-button{
					height:0.16rem;
				}
				.am-modal-button::before{
					height:0;
				}
			}
		}
	}
}
